
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'
import PlanOrderSummary from '@/components/pages/plans/PlanOrderSummary.vue'
import type { StatusType } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    SinglePageContent,
    PlanOrderSummary,
    TmButton,
  },
  setup() {
    const orderStatus = ref<StatusType>({
      color: 'green',
      name: 'paid',
      outline: false,
    })

    return {
      orderStatus,
    }
  },
})
